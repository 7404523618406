import React, { useState } from 'react';
import '../styles/Header.css';
import '../styles/App.css';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const location = useLocation();

  if (location.pathname === '/page/' || location.pathname === '/page' || location.pathname === '/page/contact/' || location.pathname === '/page/contact' || location.pathname === '/page/privacy/' || location.pathname === '/page/privacy' || location.pathname === '/page/terms/' || location.pathname === '/page/terms') {
    return (
<header
  id="header"
  className='header-transparent header-semi-transparent header-semi-transparent-dark header-effect-shrink'
  style={{height: "110px"}}
>
  <div
    className="header-body header-effect-shrink border-top-0 "
  >
    <div className="wrapper-sticky-fixed9" style={{ margin: "0px 0px -56px" }}>
      <div
        className="elementor-element elementor-element-5eabd54d e-lazyloaded"
        data-id="5eabd54d"
        data-element_type="container"
        style={{ position: "relative", zIndex: 99999, padding: 10 }}
      >
        <div className="e-con-inner">
          <div
            className="elementor-element elementor-element-41e6165c e-con-full e-flex e-con e-child"
            data-id="41e6165c"
            data-element_type="container"
          >
            <div
              className="elementor-element elementor-element-668977bf e-con-full e-flex e-con e-child"
              data-id="668977bf"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-52714bcf elementor-widget elementor-widget-image"
                data-id="52714bcf"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <a
                    href="/page/"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      fetchpriority="high"
                      src="/files/logo.png"
                      className="attachment-full size-full wp-image-224"
                      alt=""
                      style={{maxWidth: "250px", width: "100%"}}
                      decoding="async"
                    />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
    )
  }

  return (
<header
  id="header"
  className='header-transparent header-semi-transparent header-semi-transparent-dark header-effect-shrink'
>
  <div
    data-elementor-type="page"
    data-elementor-id={189}
    className="elementor elementor-189"
  >
    <div className="wrapper-sticky-fixed9" style={{ margin: "0px 0px -56px" }}>
      <div
        className="elementor-element elementor-element-5eabd54d e-lazyloaded"
        data-id="5eabd54d"
        data-element_type="container"
        style={{ position: "relative", zIndex: 99999, padding: 10 }}
      >
        <div className="e-con-inner">
          <div
            className="elementor-element elementor-element-41e6165c e-con-full e-flex e-con e-child"
            data-id="41e6165c"
            data-element_type="container"
          >
            <div
              className="elementor-element elementor-element-668977bf e-con-full e-flex e-con e-child"
              data-id="668977bf"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-52714bcf elementor-widget elementor-widget-image"
                data-id="52714bcf"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <a
                    href="/"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      fetchpriority="high"
                      src="/files/logo.png"
                      className="attachment-full size-full wp-image-224"
                      alt=""
                      decoding="async"
                      style={{maxWidth: "250px", width: "100%"}}
                    />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

  )
}

export default Header;
