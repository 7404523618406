// src/pages/Home.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Home = () => (
  <>
    <Header />
    <div id="main" role="main" className="main">
      <section
        className="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-5 mb-0"
        style={{
          backgroundImage: 'url("/files/bg1.webp")',
          backgroundPosition: "center center",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 align-self-center p-static order-2 text-center">
              <h1 className="text-8 font-weight-semi-bold text-uppercase">
                Politika zasebnosti
              </h1>
            </div>
          </div>
        </div>
      </section>
      <div className="container py-5 my-3">
        <div className="col-12 mb-5">
          <div className="d-flex flex-wrap justify-content-between mb-3">
            <h2 className="mb-0" style={{ fontWeight: 600 }} />
          </div>
          <div className="children-img-fluid">
            <p>
              Hvala, ker obiskujete Live by Travels. Ta politika zasebnosti
              opisuje, kako zbiramo, uporabljamo in ščitimo vaše osebne
              podatke, ko uporabljate našo spletno stran in storitve.
            </p>
            <h2>1. Podatki, ki jih zbiramo</h2>
            <p>
              Lahko zbiramo različne podatke, ko obiščete našo spletno stran, v
             ključno z:
            </p>
            <ul>
              <li>
                Osebni podatki, kot so ime, e-poštni naslov, telefonska številka,
                ko se registrirate za uporabo naših storitev ali nas kontaktirate.
              </li>
              <li>
                Tehnični podatki, kot so IP-naslov, vrsta brskalnika in druge
                podobne informacije za izboljšanje vaše izkušnje na naši
                spletni strani.
              </li>
            </ul>
            <h2>2. Kako uporabljamo vaše podatke</h2>
            <p>
              Vaše podatke uporabljamo za različne namene, vključno z:
            </p>
            <ul>
              <li>Za zagotavljanje in izboljšanje naših storitev;</li>
              <li>Za prilagajanje vaše izkušnje na naši spletni strani;</li>
              <li>
                Za pošiljanje obvestil, ponudb ali drugih pomembnih informacij;
              </li>
              <li>
                Za izpolnjevanje zakonskih zahtev in veljavnih predpisov.
              </li>
            </ul>
            <h2>3. Varnost podatkov</h2>
            <p>
              Sprejemamo ustrezne ukrepe za zaščito vaših osebnih podatkov pred
              nepooblaščenim dostopom, spremembami ali uničenjem. Kljub temu
              nobena metoda prenosa preko interneta ali elektronska hramba ni
              100% varna in ne moremo zagotoviti absolutne varnosti vaših podatkov.
            </p>
            <h2>4. Vaše pravice</h2>
            <p>
              Imate pravico dostopati do svojih osebnih podatkov, jih
              popraviti ali izbrisati. Svojo privolitev za obdelavo podatkov
              lahko kadar koli prekličete, tako da nas kontaktirate preko
              spodnjih kontaktnih informacij.
            </p>
            <h2>5. Spremembe politike zasebnosti</h2>
            <p>
              To politiko zasebnosti lahko občasno posodobimo. Vsaka sprememba
              bo objavljena na tej strani, datum posodobitve pa bo odražen na
              vrhu politike.
            </p>
            <h2>6. Kontakt</h2>
            <p>
              Če imate kakršna koli vprašanja ali zahteve v zvezi s to
              politiko zasebnosti, nas kontaktirajte na:
            </p>
            <p>
              Email:{" "}
              <a href="mailto:support@livebytravels.com">
                support@livebytravels.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </>
);

export default Home;
