import React, { useState, useEffect } from 'react';
import '../styles/CookieBox.css';
import { useNavigate } from 'react-router-dom';

const CookieBox = () => {
  const [visible, setVisible] = useState(true);

  const [showCookieBox, setShowCookieBox] = useState(true);

    const extractGclidFromURL = () => {
        const currentURL = window.location.href;
        if (currentURL.includes('?')) {
            const queryString = currentURL.split('?')[1];
            const params = new URLSearchParams(queryString);
            return params.get('gclid');
        }
        return null;
    };

    const finalGclid = extractGclidFromURL();

    const handleAcceptCookies = () => {
        if (finalGclid !== null) {
            window.location.href = `/page/?gclid=${finalGclid}`;
        } else {
            window.location.href = "/page/";
        }
    };

    const handleDeclineCookies = () => {
        window.location.href = "/privacy/";
    };

  if (!visible) return null;

  return (
    <div className="cookiebox">
      <style
  dangerouslySetInnerHTML={{ __html: "html, body{\n    overflow: hidden;\n}" }}
/>
      <div className="cookiebox-container">
        <h2>🍪 Uporabljamo piškotke</h2>
        <p>Za izboljšanje vaše izkušnje brskanja in analizo prometa na naši spletni strani uporabljamo piškotke. Z nadaljnjo uporabo te strani soglašate z uporabo naših piškotkov.</p>
        <div className="cookiebox-buttons">
          <button id="btnYes" onClick={handleAcceptCookies}>Da, sprejmem</button>
          <button id="btnNo" onClick={handleDeclineCookies}>Ne, ne sprejmem</button>
        </div>
      </div>
    </div>
  );
};

export default CookieBox;
