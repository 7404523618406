// src/pages/Home.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Terms = () => (
  <>
    <Header />
    <div id="main" role="main" className="main">
      <section
        className="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-5 mb-0"
        style={{
          backgroundImage: 'url("/files/bg1.webp")',
          backgroundPosition: "center center"
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 align-self-center p-static order-2 text-center">
              <h1 className="text-8 font-weight-semi-bold text-uppercase">
                Pogoji uporabe
              </h1>
            </div>
          </div>
        </div>
      </section>
      <div className="container py-5 my-3">
        <div className="col-12 mb-5">
          <div className="d-flex flex-wrap justify-content-between mb-3">
            <h2 className="mb-0" style={{ fontWeight: 600 }} />
          </div>
          <div className="children-img-fluid">
            <p>
              Dobrodošli na Live by Travels! Ti Pogoji uporabe urejajo uporabo naše spletne strani in storitev, ki jih nudimo. Z uporabo naše strani potrjujete in se strinjate, da boste upoštevali te pogoje. Prosimo, natančno jih preberite, preden nadaljujete z uporabo naše strani.
            </p>
            <h2>1. Uporaba naše spletne strani</h2>
            <p>
              Live by Travels ponuja informacije o hotelih in igralnicah v Sloveniji za informativne in promocijske namene. Strani ne smete uporabljati za druge komercialne namene ali za promocijo storitev tretjih oseb brez našega predhodnega soglasja.
            </p>
            <h2>2. Intelektualna lastnina</h2>
            <p>
              Vsebina te strani, vključno z besedili, slikami, grafikami in logotipi, je last Live by Travels in je zaščitena z zakonodajo o intelektualni lastnini. Vsebino ne smete kopirati, razmnoževati ali distribuirati brez našega pisnega dovoljenja.
            </p>
            <h2>3. Odgovornost za informacije</h2>
            <p>
              Live by Travels si prizadeva zagotoviti točne in posodobljene informacije. Vendar pa ne zagotavljamo nobenega jamstva za točnost informacij in ne prevzemamo odgovornosti za škodo, ki bi nastala zaradi uporabe teh informacij. Odgovorni ste za vse odločitve, ki jih sprejmete na podlagi informacij na tej strani.
            </p>
            <h2>4. Povezave na strani tretjih oseb</h2>
            <p>
              Naša stran lahko vsebuje povezave na strani tretjih oseb. Te povezave so zagotovljene za vašo udobje, vendar ne prevzemamo odgovornosti za vsebino ali prakse zasebnosti drugih strani. Priporočamo, da preberete pogoje uporabe in politike zasebnosti teh strani.
            </p>
            <h2>5. Spremembe pogojev uporabe</h2>
            <p>
              Pridržujemo si pravico do spremembe teh Pogojev uporabe kadarkoli. Vsaka sprememba bo objavljena na tej strani, datum posodobitve pa bo naveden na vrhu dokumenta. Priporočamo, da to stran redno preverjate za posodobitve.
            </p>
            <h2>6. Kontakt</h2>
            <p>
              Če imate kakršna koli vprašanja ali zahteve v zvezi s temi Pogoji uporabe, nas prosimo kontaktirajte na:
            </p>
            <p>
              Email:{" "}
              <a href="mailto:support@livebytravels.com">
                support@livebytravels.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </>
);

export default Terms;
