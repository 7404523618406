import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/Footer.css';

const Footer = () => {

  const [isMenuActive, setIsMenuActive] = useState(false);
  const location = useLocation();

  if (location.pathname === '/page/' || location.pathname === '/page' || location.pathname === '/page/contact/' || location.pathname === '/page/contact' || location.pathname === '/page/privacy/' || location.pathname === '/page/privacy' || location.pathname === '/page/terms/' || location.pathname === '/page/terms') {
    return (
<footer
  id="footer"
  className="mt-0 border-0"
  style={{ backgroundColor: "black" }}
>
  <div className="container">
    <div className="row py-5">
      <div className="col-sm-6 col-md-6 mb-5 mb-lg-0">
        <div className="d-flex flex-column flex-md-row justify-content-center text-center text-sm-start justify-content-sm-start flex-wrap flex-md-nowrap">
          <div className="me-0 me-md-4 mb-3">
            <img src="/files/logo.png" alt="" width={110} />
          </div>
          <div>
            <h5 className="text-5 text-transform-none mb-4">Kontakt:</h5>
            <div className="divider divider-small divider-primary">
              <hr className="mx-auto ms-sm-0" />
            </div>
            <p className="text-4 mb-0">
              <i className="fa fa-home" /> Live by Travels
            </p>
            <p className="text-4 mb-0">
              <a href="mailto:info@livebytravels.com">
                <i className="fa fa-envelope" />
                info@livebytravels.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className=" col-sm-6 col-md-3 mb-5 mb-lg-0 text-center text-sm-start"></div>
      <div className=" col-sm-6 col-md-3 mb-5 mb-lg-0 text-center text-sm-start">
        <h5 className="text-5 text-transform-none mb-4">Povezave:</h5>
        <div className="divider divider-small divider-primary">
          <hr className="mx-auto ms-sm-0" />
        </div>
        <p className="mb-1">
          <Link to="/page/privacy" className="text-4 link-hover-style-1">
            Politika zasebnosti
          </Link>
        </p>
        <p className="mb-1">
          <Link to="/page/terms" className="text-4 link-hover-style-1">
            Pogoji uporabe
          </Link>
        </p>
        <p className="mb-1">
          <Link to="/page/contact" className="text-4 link-hover-style-1">
            Kontaktirajte nas
          </Link>
        </p>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-7 text-center text-sm-start">
        <p className="text-color-white">
          {" "}
          © 2024 Live by Travels. Vse pravice pridržane.
        </p>
      </div>
      <div className="col-sm-5 text-center text-sm-end"></div>
    </div>
  </div>
</footer>

    )
  }

  return (
    <footer
    id="footer"
    className="mt-0 border-0"
    style={{ backgroundColor: "black" }}
  >
    <div className="container">
      <div className="row py-5">
        <div className="col-sm-6 col-md-6 mb-5 mb-lg-0">
          <div className="d-flex flex-column flex-md-row justify-content-center text-center text-sm-start justify-content-sm-start flex-wrap flex-md-nowrap">
            <div className="me-0 me-md-4 mb-3">
              <img src="/files/logo.png" alt="" width={110} />
            </div>
            <div>
              <h5 className="text-5 text-transform-none mb-4">Kontakt:</h5>
              <div className="divider divider-small divider-primary">
                <hr className="mx-auto ms-sm-0" />
              </div>
              <p className="text-4 mb-0">
                <i className="fa fa-home" /> Live by Travels
              </p>
              <p className="text-4 mb-0">
                <a href="mailto:info@livebytravels.com">
                  <i className="fa fa-envelope" />
                  info@livebytravels.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className=" col-sm-6 col-md-3 mb-5 mb-lg-0 text-center text-sm-start"></div>
        <div className=" col-sm-6 col-md-3 mb-5 mb-lg-0 text-center text-sm-start">
          <h5 className="text-5 text-transform-none mb-4">Povezave:</h5>
          <div className="divider divider-small divider-primary">
            <hr className="mx-auto ms-sm-0" />
          </div>
          <p className="mb-1">
    <Link to="/privacy" className="text-4 link-hover-style-1">
      Politika zasebnosti
    </Link>
  </p>
  <p className="mb-1">
    <Link to="/terms" className="text-4 link-hover-style-1">
      Pogoji uporabe
    </Link>
  </p>
  <p className="mb-1">
    <Link to="/contact" className="text-4 link-hover-style-1">
      Kontaktirajte nas
    </Link>
  </p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-7 text-center text-sm-start">
          <p className="text-color-white">
            {" "}
            © 2024 Live by Travels. Vse pravice pridržane.
          </p>
        </div>
        <div className="col-sm-5 text-center text-sm-end"></div>
      </div>
    </div>
  </footer>
  )


};

export default Footer;
