// src/pages/Home.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Home = () => (
  <>
    <Header />
<div id="main" role="main" className="main">
  <section
    className="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-5 mb-0"
    style={{
      backgroundImage: 'url("/files/bg1.webp")',
      backgroundPosition: "center center"
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-md-12 align-self-center p-static order-2 text-center">
        <h1 className="text-8 font-weight-semi-bold text-uppercase">
          Glavne Destinacije Slovenije za Luksuz in Čustva
        </h1>
        <p style={{ color: "white", marginTop: 20 }}>
          Vstopite v svet elegance in čustev z najboljšimi destinacijami Slovenije. Od luksuznih nastanitev do razburljivih doživetij, raziščite izbor destinacij, kjer se prefinjenost sreča z avanturo.
        </p>
        </div>
      </div>
    </div>
  </section>
  <div className="container py-5 my-3">
    <div className="col-12 mb-5">
      <div className="d-flex flex-wrap justify-content-between mb-3">
        <h2 className="mb-0" style={{ fontWeight: 600 }} />
      </div>
      <div className="children-img-fluid">
      <h2>O Nas</h2>
<p>
  Live by Travels je vaš glavni vodič za luksuzne nastanitve in najboljše ekskluzivne destinacije v Sloveniji.{" "}
</p>
<p>
  Navdušeni smo nad tem, da predstavimo najboljše, kar Slovenija ponuja, od osupljivih nastanitev in edinstvenih zabavnih doživetij do vrhunskih storitev in tople domače gostoljubnosti. Naše poslanstvo je približati vam eleganco, čustva in edinstven čar, ki opredeljujejo luksuzna pobega v Sloveniji.{" "}
</p>
<p>
  Ne glede na to, ali načrtujete nepozabno potovanje ali želite raziskovati, smo tu, da vam pomagamo odkriti nepozabna doživetja po vsej državi.
</p>
      </div>
    </div>
    <div id="first" className="container pb-4">
      <div className="row">
        <div className="col">
          <ul className="nav d-flex flex-wrap gap-3 justify-content-center sort-source sort-source-style-3">
            <li data-option-value="*" className="active">
            <a className="text-6 font-weight-semi-bold text-color-dark text-hover-primary text-decoration-none">
              Seznam najboljših hotelov s kazinoji v Sloveniji
            </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="row pb-5">
      <div className="col">
        <div className="sort-destination-loader pt-2 sort-destination-loader-loaded">
          <div
            className="row portfolio-list py-3 sort-destination"
            data-sort-id="portfolio"
            data-filter="*"
            style={{ position: "relative", height: "100%" }}
          >
            <div className="col-lg-4 col-md-6 pb-3 isotope-item">
              <div className="card shadow rounded-0">
                <div className="card-img-wrapper p-relative">
                  <img
                    className="card-img-top rounded-0"
                    src="/files/cas1.avif"
                    style={{ height: 268, objectFit: "cover" }}
                  />
                </div>
                <div className="card-body p-3">
                  <h4 className="card-title mb-1 text-4 font-weight-bold">
                    <a
                      href="https://www.expedia.com/Nova-Gorica-Hotels-Perla.h2539148.Hotel-Information"
                      className="text-color-dark text-color-hover-primary text-decoration-none"
                    >
                      Perla, Resort & Entertainment
                    </a>
                  </h4>
                  <p>Kidriceva 7, Nova Gorica, 5000 Slovenija</p>
                  <div className="divider divider-small divider-primary">
                    <hr />
                  </div>
                  <div data-mh="promotion">
                    <p>
                    V igralniško-zabaviščnem centru Perla, ki se nahaja v središču Nove Gorice, se nahaja štirizvezdični hotel, ki gostom ponuja udobje v 225 sobah, 20 apartmajih in 4 luksuznih apartmajih.
                    </p>
                  </div>
                  <br />
                  <br />
                  <div className="card-body-footer d-flex gap-2">
                    <a
                      href="https://www.expedia.com/Nova-Gorica-Hotels-Perla.h2539148.Hotel-Information"
                      className="btn btn-outline-primary rounded-0 flex-grow-1"
                    >
                      Rezerviraj zdaj
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pb-3 isotope-item">
              <div className="card shadow rounded-0">
                <div className="card-img-wrapper p-relative">
                  <img
                    className="card-img-top rounded-0"
                    src="/files/cas2.webp"
                    style={{ height: 268, objectFit: "cover" }}
                  />
                </div>
                <div className="card-body p-3">
                  <h4 className="card-title mb-1 text-4 font-weight-bold">
                    <a
                      href="https://www.expedia.com/Ljubljana-Hotels-Hotel-Lev.h46773.Hotel-Information"
                      className="text-color-dark text-color-hover-primary text-decoration-none"
                    >
                      Exe Lev
                    </a>
                  </h4>
                  <p>Vosnjakova ulica 1, Ljubljana, 1000 Slovenija</p>
                  <div className="divider divider-small divider-primary">
                    <hr />
                  </div>
                  <div data-mh="promotion">
                    <p>
                    Hotel Exe Lev z impresivnim steklenim pročeljem, je zgrajen na mestu nekdanje prestižne restavracije in sprejema goste že od leta 1964. Zaradi storitev najvišje kakovosti je pridobil posebno mesto med obiskovalci.
                    </p><br /><br /><br />
                  </div>
                  <div className="card-body-footer d-flex gap-2">
                    <a
                      href="https://www.expedia.com/Ljubljana-Hotels-Hotel-Lev.h46773.Hotel-Information"
                      className="btn btn-outline-primary rounded-0 flex-grow-1"
                    >
                      Rezerviraj zdaj
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pb-3 isotope-item">
              <div className="card shadow rounded-0">
                <div className="card-img-wrapper p-relative">
                  <img
                    className="card-img-top rounded-0"
                    src="/files/66114752.jpg"
                    style={{ height: 268, objectFit: "cover" }}
                  />
                </div>
                <div className="card-body p-3">
                  <h4 className="card-title mb-1 text-4 font-weight-bold">
                    <a
                      href="https://www.expedia.com/Kozina-Hotels-Casino-Hotel-ADMIRAL-Kozina.h9806098.Hotel-Information"
                      className="text-color-dark text-color-hover-primary text-decoration-none"
                    >
                     Casino & Hotel ADMIRAL Kozina
                    </a>
                  </h4>
                  <p>Bazoviška cesta 23, 6240 Kozina, Slovenija</p>
                  <div className="divider divider-small divider-primary">
                    <hr />
                  </div>
                  <div data-mh="promotion">
                    <p>
                    Nastanitev Casino & Hotel ADMIRAL Kozina je v Kozini, 11 km od znamenitosti Škocjanske jame, in nudi skupni prostor za druženje, brezplačno zasebno parkirišče, bar ter kazino.
                    </p><br /> <br />
                  </div>
                  <br />
                  <div className="card-body-footer d-flex gap-2">
                    <a
                      href="https://www.expedia.com/Kozina-Hotels-Casino-Hotel-ADMIRAL-Kozina.h9806098.Hotel-Information"
                      className="btn btn-outline-primary rounded-0 flex-grow-1"
                    >
                      Rezerviraj zdaj
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pb-3 isotope-item">
              <div className="card shadow rounded-0">
                <div className="card-img-wrapper p-relative">
                  <img
                    className="card-img-top rounded-0"
                    src="/files/103657254.jpg"
                    style={{ height: 268, objectFit: "cover" }}
                  />
                </div>
                <div className="card-body p-3">
                  <h4 className="card-title mb-1 text-4 font-weight-bold">
                    <a
                      href="https://www.expedia.com/Sezana-Hotels-Hotel-Safir-Casino.h22197987.Hotel-Information"
                      className="text-color-dark text-color-hover-primary text-decoration-none"
                    >
                      Safir Hotel Casino
                    </a>
                  </h4>
                  <p>Partizanska cesta 149, 6210 Sežana, Slovenija</p>
                  <div className="divider divider-small divider-primary">
                    <hr />
                  </div>
                  <div data-mh="promotion">
                    <p>
                    Novo zgrajen kompleks Safir Hotel Casino se nahaja ob italijansko-slovenski meji, približno 2 km od središča Sežane in v bližini različnih znamenitosti.
                    </p>
                  </div>
                  <br />
                  <br />
                  <div className="card-body-footer d-flex gap-2">
                    <a
                      href="https://www.expedia.com/Sezana-Hotels-Hotel-Safir-Casino.h22197987.Hotel-Information"
                      className="btn btn-outline-primary rounded-0 flex-grow-1"
                    >
                      Rezerviraj zdaj
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pb-3 isotope-item">
              <div className="card shadow rounded-0">
                <div className="card-img-wrapper p-relative">
                  <img
                    className="card-img-top rounded-0"
                    src="/files/130247009.jpg"
                    style={{ height: 268, objectFit: "cover" }}
                  />
                </div>
                <div className="card-body p-3">
                  <h4 className="card-title mb-1 text-4 font-weight-bold">
                    <a
                      href="https://www.expedia.com/Rogaska-Slatina-Hotels-ATLANTIDA-BOUTIQUE-HOTEL.h17316606.Hotel-Information"
                      className="text-color-dark text-color-hover-primary text-decoration-none"
                    >
                    Atlantida Boutique Hotel
                    </a>
                  </h4>
                  <p>
                  Cvetlični hrib 1, 3250 Rogaška Slatina, Slovenija
                  </p>
                  <div className="divider divider-small divider-primary">
                    <hr />
                  </div>
                  <div data-mh="promotion">
                    <p>
                    Sodoben in razkošen hotel Atlantida Boutique se nahaja v dobro znanem zdraviliškem mestu Rogaška Slatina, le 200 m od osrednjega parka in Zdravilišča Rogaška. 
                    </p>
                  </div>
                  <br />
                  <br />
                  <div className="card-body-footer d-flex gap-2">
                    <a
                      href="https://www.expedia.com/Rogaska-Slatina-Hotels-ATLANTIDA-BOUTIQUE-HOTEL.h17316606.Hotel-Information"
                      className="btn btn-outline-primary rounded-0 flex-grow-1"
                    >
                      Rezerviraj zdaj
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pb-3 isotope-item">
              <div className="card shadow rounded-0">
                <div className="card-img-wrapper p-relative">
                  <img
                    className="card-img-top rounded-0"
                    src="/files/131137519.jpg"
                    style={{ height: 268, objectFit: "cover" }}
                  />
                </div>
                <div className="card-body p-3">
                  <h4 className="card-title mb-1 text-4 font-weight-bold">
                    <a
                      href="https://www.expedia.com/Rogaska-Slatina-Hotels-Grand-Hotel-Rogaska.h3091555.Hotel-Information"
                      className="text-color-dark text-color-hover-primary text-decoration-none"
                    >
                      Grand Hotel Rogaška Premium
                    </a>
                  </h4>
                  <p>Zdraviliški trg 11, 3250 Rogaška Slatina, Slovenija</p>
                  <div className="divider divider-small divider-primary">
                    <hr />
                  </div>
                  <div data-mh="promotion">
                    <p>
                    Hotel Grand Rogaška Premium se nahaja v središču Rogaške Slatine, ponuja pa wellness, spa, bazenski kompleks, klimatizirane sobe, igralnico, restavracijo in brezplačen brezžični internet v vseh prostorih.
                    </p>
                  </div>
                  <div className="card-body-footer d-flex gap-2">
                    <a
                      href="https://www.expedia.com/Rogaska-Slatina-Hotels-Grand-Hotel-Rogaska.h3091555.Hotel-Information"
                      className="btn btn-outline-primary rounded-0 flex-grow-1"
                    >
                      Rezerviraj zdaj
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bounce-loader">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    <Footer />
  </>
);

export default Home;
